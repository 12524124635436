<template>
  <div :class="alertClass" class="alert" role="alert">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    status: {
      type: String,
      require: true,
      validator: value => {
        return [
          'primary',
          'secondary',
          'success',
          'danger',
          'warning',
          'info',
          'light',
          'dark',
        ].indexOf(value) !== -1;
      },
      default: 'danger',
    },
  },
  computed: {
    alertClass () {
      return `alert-${this.status}`;
    },
  },
};

</script>

<style lang="scss"></style>
